<!--  -->
<template>
  <div class="approval-detail">
    <div class="base-info card">
      <div class="row1">
        <div class="title">{{ info.staffName }}的用车申请审批</div>
        <div class="approve-status">
          {{ info.approveStatus | formatApproveStatus }}
        </div>
      </div>
      <div class="row2">
        <span>审批编号：{{ info.applyCode }}</span>
      </div>
    </div>
    <div class="values card">
      <ul class="value-ul">
        <li class="item">
          <div class="label">申请车辆</div>
          <div class="value">{{ info.platingNumber }}</div>
        </li>
        <li class="item">
          <div class="label">开始时间</div>
          <div class="value">{{ info.beginTimeDesc }}</div>
        </li>
        <li class="item">
          <div class="label">预计结束时间</div>
          <div class="value">{{ info.endTimeDesc }}</div>
        </li>

        <li class="item">
          <div class="label">去向</div>
          <div class="value">{{ info.origin || "--" }}</div>
        </li>
        <li class="item">
          <div class="label">用车事由</div>
          <div class="value">{{ info.reason || "--" }}</div>
        </li>
        <li class="item">
          <div class="label">内部乘车人</div>
          <div class="value">
            {{ info.passengersName | formatePassengersName }}
          </div>
        </li>

        <li class="item">
          <div class="label">借用油卡</div>
          <div class="value">
            {{ info.cardType | formateCardType }}
          </div>
        </li>
        <li class="item">
          <div class="label">油卡名称</div>
          <div class="value">
            {{ info.cardVO ? info.cardVO.cardName : "--" }}
          </div>
        </li>
      </ul>
    </div>
    <div class="process card">
      <div class="title">审批流程</div>
      <ul class="process-ul">
        <li
          v-for="(item, index) in info.approveHistorys"
          :key="index"
          class="item"
        >
          <div class="left">
            <img :src="approvalAvatar" alt="" class="avatar" />
            <div class="split"></div>
          </div>
          <div class="right">
            <div class="row1">
              <div class="name">{{ item.nodeName }}</div>
              <div class="time">{{ item.createTime }}</div>
            </div>
            <div class="user-name">{{ item.approveUserName }}</div>
            <div class="status" v-if="item.nodeName != '抄送人'">
              {{ item.status | formatApproveStatus }}
            </div>
            <div class="comment" v-if="item.commit">
              {{ item.commit }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 操作按钮 -->
    <div
      class="footer-buttons"
      v-if="
        0 == type &&
        0 == info.approveStatus &&
        loginStaff === info.currentApproveUser
      "
    >
      <van-button class="pass" @click="approval(1)">同意</van-button>
      <van-button class="reject-btn" @click="approval(2)">拒绝</van-button>
    </div>

    <!-- 操作按钮 -->
    <div class="footer-buttons" v-if="1 == type && 0 == info.approveStatus">
      <div class="icon-text" @click="handleCancel">
        <img :src="cancel" alt="" class="img" />
        <span class="text">撤销</span>
      </div>
    </div>

    <!-- 操作按钮 -->
    <!-- <div class="footer-buttons" v-if="1 == type && 3 == info.approveStatus">
      <div class="icon-text" @click="handleReEdit">
        <img :src="edit" alt="" class="img" />
        <span class="text">重新编辑</span>
      </div>
    </div> -->

    <!-- 操作按钮 -->
    <div
      class="footer-buttons"
      v-if="1 == type && [1, 2, 3].indexOf(info.approveStatus) > -1"
    >
      <div class="icon-text" @click="handleEdit">
        <img :src="edit" alt="" class="img" />
        <span class="text">再次提交</span>
      </div>
    </div>

    <van-popup v-model="formVisible" round>
      <div class="approval-box">
        <div class="title">审批意见</div>
        <van-form class="app">
          <textarea
            v-model="form.commit"
            class="message"
            placeholder="请输入审批意见（非必填）"
          />
          <div class="footer">
            <div @click="formVisible = false" class="cancel">取消</div>
            <div @click="onsubmit" class="confirm">确定</div>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  useApplyInfo,
  cancleApply,
  useApplyApprove,
} from "@/api/cart/manage/index.js";
import Cookies from "js-cookie";
import approvalAvatar from "@/assets/images/approvalDetail/approvalAvatar.png";
import edit from "@/assets/images/approvalDetail/edit.png";
import cancel from "@/assets/images/approvalDetail/cancel.png";
import { Dialog } from "vant";
import { Toast } from "vant";

export default {
  data() {
    return {
      loginStaff: localStorage.getItem("staffCode_H5"),
      processConfigInfo: {},
      info: {},
      nodesList: [],
      approvalAvatar,
      edit,
      cancel,
      formVisible: false,
      form: {
        approveStatus: -1,
      },
    };
  },
  name: "approvalDetail",
  components: {},
  computed: {
    type() {
      return this.$route.query.type;
    },
  },
  filters: {
    formatApproveStatus(val) {
      const rules = {
        0: "审批中",
        1: "审批通过",
        2: "审批驳回",
        3: "已撤销",
      };
      return rules[val] || val;
    },
    formatePassengersName(val) {
      if (!val) {
        return "--";
      } else {
        return val.join(",");
      }
    },
    formateCardType(val) {
      const rules = {
        0: "否",
        1: "是",
      };
      return rules[val];
    },
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      useApplyInfo({
        applyCode: this.$route.query.applyCode,
      }).then((res) => {
        this.info = res.data;
      });
    },
    // 撤销
    handleCancel() {
      Dialog.confirm({
        title: "确定撤销",
        message: "是否确认撤销当前审批？",
      }).then(
        (res) => {
          cancleApply({
            applyCode: this.$route.query.applyCode,
          }).then((res) => {
            Toast("撤消成功");
            this.$router.back();
          });
        },
        (rej) => {}
      );
    },

    approval(approveStatus) {
      this.form.approveStatus = approveStatus;
      if (1 == approveStatus) {
        this.form.commit = "同意";
      } else {
        this.form.commit = "";
      }
      this.formVisible = true;
    },

    onsubmit() {
      let postData = {
        ...this.form,
        applycode: this.$route.query.applyCode,
      };
      useApplyApprove(postData).then((res) => {
        this.formVisible = false;
        Toast("审核成功");
        this.getInfo();
        this.$router.back();
      });
    },

    // 编辑-带code
    handleReEdit() {
      this.$router.push({
        path: "/approvalCreat",
        query: {
          // applyCode: this.$route.query.applyCode,
          editType: 1,
          // platingNumber: item.platingNumber,
          cartCode: item.bizCode,
          date: this.date,
          bizCode: item.processCode,
          tagKey: "CAR_APPLY_APPROVE",
        },
      });
    },

    // 编辑-不带code，增加一条新的
    handleEdit() {
      this.$router.push({
        path: "/approvalCreat",
        query: {
          // applyCode: this.$route.query.applyCode,
          // platingNumber: item.platingNumber,
          cartCode: item.bizCode,
          date: this.date,
          bizCode: item.processCode,
          tagKey: "CAR_APPLY_APPROVE",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-detail {
  min-height: 100%;
  background: #fafafa;
  width: 7.5rem;
  position: relative;
  padding: 1px;
  .card {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: #fff;
    margin-bottom: 0.3rem;
    border: 1px solid transparent;
  }
  .base-info {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    .row1 {
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 0.34rem;
        font-weight: bold;
        color: #333333;
        line-height: 0.48rem;
      }
      .approve-status {
      }
    }
    .row2 {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #999999;
      line-height: 0.37rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.12rem;
    }
  }

  .values {
    .value-ul {
      .item {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.3rem 0;
        .label {
          height: 0.37rem;
          font-size: 0.26rem;
          color: #999999;
          line-height: 0.37rem;
          margin-bottom: 0.09rem;
        }
        .img-value {
          width: 2rem;
          height: 2rem;
        }
        .file-box {
          margin-bottom: 0.2rem;
          .file-value {
            border-bottom: 1px solid #0094ee;
            color: #0094ee;
          }
        }

        .value {
          font-size: 0.3rem;
          color: #333333;
          line-height: 0.42rem;
          word-break: break-all;
        }
      }
      .item:last-of-type {
        border: none;
      }
    }
  }

  .process {
    margin-bottom: 1.24rem;
    .title {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      height: 0.42rem;
      font-size: 0.3rem;
      font-weight: bolder;
      color: #333333;
      line-height: 0.42rem;
    }
    .process-ul {
      .item {
        display: flex;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 0.9rem;
          margin-right: 0.2rem;
          .avatar {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 0.45rem;
          }
          .split {
            width: 0.02rem;
            background: #cccccc;
            flex: 1;
          }
        }

        .right {
          flex: 1;
          margin-bottom: 0.6rem;
          .row1 {
            display: flex;
            justify-content: space-between;
            .name {
              font-size: 0.3rem;
              color: #333333;
              line-height: 0.42rem;
            }
            .time {
              font-size: 0.26rem;
              color: #999999;
              line-height: 0.37rem;
              white-space: nowrap;
            }
          }
          .user-name {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
          .status {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
          .comment {
            margin-top: 0.2rem;
            width: 5.85rem;
            background: #f1f5f6;
            border-radius: 0 0.16rem 0.16rem 0.16rem;
            padding: 0.16rem;
            font-size: 0.22rem;
            color: #666666;
            line-height: 0.3rem;
          }
        }
      }
      .item:last-of-type {
        .left {
          .split {
            display: none;
          }
        }
        .right {
          margin-bottom: 0.6rem;
        }
      }
    }
  }
  .footer-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.16rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
    .reject-btn {
      width: 2.16rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }

    .icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 2.5rem;
      .img {
        width: 0.4rem;
        height: 0.4rem;
      }
      .text {
        width: 0.52rem;
        height: 0.37rem;
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.37rem;
        white-space: nowrap;
      }
    }
  }

  .approval-box {
    width: 5.7rem;
    background: #ffffff;
    border-radius: 0.16rem;
    .title {
      height: 0.9rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.45rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .app {
      .message {
        width: 5.3rem;
        height: 2.09rem;
        background: #f4f9fb;
        margin-left: 0.2rem;
        resize: none;
        padding: 0.2rem;
      }

      .footer {
        height: 0.9rem;
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        color: #0094ee;
        line-height: 0.45rem;

        .cancel {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .confirm {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
